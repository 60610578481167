import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CONFIG } from '@proman/config';
import { RequestService } from '@proman/services/request.service';
import { ToastService } from '@proman/services/toast.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'pm-login-verification-dialog',
  template: `
        <pro-dialog-title title="login_to_verify_identity"></pro-dialog-title>
        @if (!login) {
          <div mat-dialog-content fxLayout="column">
            <pm-login-form [credentials]="credentials" (onSubmit)="handleSubmit($event)"></pm-login-form>
          </div>
        }
    `
})

export class LoginVerificationDialogComponent {
  credentials = { username: '', password: '' };
  currUser: CurrUser;
  login = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { data: any[] },
    private dialogRef: MatLegacyDialogRef<LoginVerificationDialogComponent>,
    private Request: RequestService,
    private Toast: ToastService,
    private store: Store,
  ) {
    this.store.select(getCurrUser).pipe(take(1)).subscribe((value) => this.currUser = value);
  }

  handleSubmit = (val: any) => {
    this.login = true;
    return this.Request
      .post(`${CONFIG.api}login`, val)
      .then((response: any) => {
        if (response.employee.id === this.currUser.person.id) {
          this.dialogRef.close(6);
        } else {
          this.Toast.pop('error', 'wrong_user');
          this.dialogRef.close(1);
        }
      })
      .catch((err) => {
        this.Toast.pop('error', err.error.message);
        this.dialogRef.close(1);
      });
  }
}
