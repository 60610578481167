import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'pm-change-password-form',
    template: `
        <form #changePasswordForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="handleSubmit(changePasswordForm)">
            <pm-txt [value]="pass.current"
                    [control]="controls.current"
                    [config]="passCurrentConfig"
                    (onChange)="onChange('current', $event)"></pm-txt>
            <pm-txt [value]="pass.one"
                    [control]="controls.one"
                    [config]="passOneConfig"
                    (onChange)="onChange('one', $event)"></pm-txt>
            <pm-txt [value]="pass.two"
                    [control]="controls.two"
                    [config]="passTwoConfig"
                    (onChange)="onChange('two', $event)"></pm-txt>
            <div class="Error"
                 *ngIf="pass.one !== pass.two && changePasswordForm.submitted">
                <span>{{ 'passwords_dont_match' | translate }}</span>
            </div>
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <pro-btn label="{{ 'confirm' | translate }}"
                        [theme]="pass.current && (pass.one == pass.two) ? 'accent' : 'primary'"
                        type="submit"></pro-btn>
            </div>
        </form>
    `
})

export class ChangePasswordFormComponent implements OnInit {
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

    passOneConfig = { variant: 'input', label: 'password', required: true, type: 'password', debounce: 0 };
    passTwoConfig = { variant: 'input', label: 'repeat_password', required: true, type: 'password', debounce: 0 };
    passCurrentConfig = { variant: 'input', label: 'current_password', required: true, type: 'password' };
    pass: any = { one: '', two: '', current: '' };
    controls: any;
    form: UntypedFormGroup;

    ngOnInit() {
        this.controls = {
            current: new UntypedFormControl('', Validators.required),
            one: new UntypedFormControl('', Validators.required),
            two: new UntypedFormControl('', Validators.required)
        };
        this.form = new UntypedFormGroup(this.controls);
    }

    onChange(property: string, value: string) {
        this.pass[property] = value;
    }

    handleSubmit(form: any) {

        if (form.valid && this.pass.one === this.pass.two) {
            this.onSubmit.emit({ new_password: this.pass.one, old_password: this.pass.current });

        }

    }
}
