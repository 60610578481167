import { Component, Output, Input, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'pm-login-form',
    template: `
        <form #loginForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="handleSubmit(loginForm)">
            <div fxLayout="column" [ngClass]=" { 'Padding-16' : isPadding }">
                <pm-txt [value]="credentials._username"
                        [control]="controls.username"
                        [config]="userConfig"
                        (onChange)="onChange('_username', $event)"></pm-txt>
                <pm-txt [value]="credentials._password"
                        [control]="controls.password"
                        [config]="passConfig"
                        (onChange)="onChange('_password', $event)"></pm-txt>
                <div fxLayout="column"
                     fxLayoutAlign="center center">
                    <pro-btn label="login"
                            theme="accent"
                            type="submit"></pro-btn>
                </div>
            </div>
        </form>
    `
})

export class LoginFormComponent implements OnInit, OnDestroy {
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onInput: EventEmitter<any> = new EventEmitter<any>();
    @Input() credentials: any;
    @Input() isPadding: boolean;
    userConfig: any;
    passConfig: any;
    form: UntypedFormGroup;
    controls: any;

    constructor() {
        this.userConfig = { key: 'username', label: 'username', required: true };
        this.passConfig = {
            key: 'password',
            label: 'password',
            required: true,
            type: 'password',
            debounce: 0
        };
    }

    ngOnInit() {
        this.controls = {
            username: new UntypedFormControl(this.credentials.username || '', Validators.required),
            password: new UntypedFormControl('', Validators.required)
        };
        this.form = new UntypedFormGroup(this.controls);

        window.addEventListener('keydown', this.handleEnter);
    }

    handleEnter = (event: KeyboardEvent) => {

        if (event.code === 'Enter' || event.key === 'Enter' || event.code === 'Enter') {
            this.onSubmit.emit(this.credentials);
        }

    };

    ngOnDestroy() {
        window.removeEventListener('keydown', this.handleEnter);
    }

    onChange(property: string, value: string) {
        this.credentials[property] = value;
        this.onInput.emit();
    }

    handleSubmit(form: FormGroupDirective) {

        if (form.valid) {
            this.onSubmit.emit(this.credentials);

        }

    }
}
