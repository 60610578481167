import { NgModule } from '@angular/core';
import { LoginComponent } from '@frontend/shared/components/login.component';
import { LoginFormComponent } from '@frontend/shared/components/login-form.component';
import { ChangePasswordFormComponent } from '@frontend/shared/components/change-password-form.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { InputsModule } from '../inputs/inputs.module';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FrontendPipesModule } from '@frontend/shared/pipes/frontend-pipes.module';
import { Fa6Module } from '@proman/fa/fa6.module';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { LoginVerificationDialogComponent } from '@frontend/shared/components/login-verification-dialog.component';
import { PromanWarningComponent } from '@proman/common-components/components/warning.component';

const COMPONENTS = [
    LoginComponent,
    LoginFormComponent,
    ChangePasswordFormComponent,
    LoginVerificationDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatLegacyChipsModule,
        MatLegacyProgressBarModule,
        PipesModule,
        InputsModule,
        MatLegacyButtonModule,
        FlexLayoutModule,
        PromanCommonComponentsModule,
        SharedComponentsModule,
        // GapiModule,
        FrontendPipesModule,
        Fa6Module,
        PromanWarningComponent,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})

export class LoginModule {}
